// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

// font awesome


@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
// Variables
@import 'variables';


@import 'custom';

// Bootstrap
@import '~bootstrap/scss/bootstrap';



// szing add ins
@import 'sizing';




.breadcrumb-item + .breadcrumb-item::before {
    font-family: "Font Awesome 5 Free";
    content: "\f105";
    font-weight: 900;
    color:teal;
}

@media only screen and (max-width: 600px)  {
    .my-height {
      height:180px;
    }
  }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px)  {
    .my-height {
      height:160px;
    }
  }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px)  {
    .my-height {
      height:190px;
    }
  }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px)  {
    .my-height {
      height:210px;
    }
  }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .my-height {
      height:210px;
    }
  }
.thumbnail{
    max-height:80px;
}
[x-cloak] { display: none; }


.shake {
    animation: effect 0.4s;
}

@keyframes effect {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
  }


